export default {
  core: {
    history: "Storico versioni",
    // history: "Cronologia",
    ok: "OK",
    cancel: "Cancella",
    read_more: "Apri",
    close_modal: "Chiudere"
  },
  coupon: {
    back: "Indietro",
    cancel: "Cancella",
    redeem: "Continua",
    input: {
      code: "Codice coupon"
    },
    modal: {
      default: {
        title: "Codice coupon",
        text: "Può pagare la caparra di {deposit_value} con un coupon valido. Se il valore del coupon non è sufficiente, può saldare l'importo rimanente con un ulteriore pagamento."
      },
      insufficient: {
        title: "Valore coupon non sufficiente",
        text: "Il coupon ha un valore di {coupon_amount}. Può comunque riscattare il coupon e in seguito fare un pagamento del saldo di {deposit_balance}."
      },
      invalid: {
        title: "Coupon non valido",
        text: "Purtroppo il coupon inserito non è valido. Si prega di utilizzare un coupon valido o selezionare un altro metodo di pagamento."
      },
      remaining: {
        title: "Pagamento del saldo",
        item: "{payment_date} Pagamento di {payment_amount} eseguito con successo",
        text: "Per favore, selezioni un metodo di pagamento con il quale desidera effettuare il pagamento del saldo di {deposit_balance}."
      }
    }
  },
  page: {
    gallery: {
      image: "Immagine",
      video: "Video",
      pano: "360°",
      plan: "Planimetria",
      gallery: "Fotogalleria",
      mute_audio: "Disattiva audio",
      unmute_audio: "Attivare audio"
    },
    whatsapp: {
      scan_code: "Scansione QR-Code",
      contact_over: "Contatto via WhatsApp App",
      whatsapp_web: "WhatsApp Web",
      whatsapp_web_contact: "Contatto via web app"
    },
    mode: {
      configuration: {
        message: "La landing page è in modalità di configurazione"
      },
      versioned: {
        message_default: "Versione vecchia",
        message: "Versione del {date}"
      }
    },
    success: {
      messageSuccessful: "Il messaggio è stato inviato con successo"
    },
    errors: {
      messageIsEmpty: "Aggiungete un messaggio",
      no_hash: {
        title: "Si è verificato un errore",
        text: "Riprova più tardi"
      },
      payment: {
        title: "Il pagamento non è stato effettuato",
        text: "Riprova più tardi",
        hint: "Messagio di errore: ",
        btn_retry: "Riprova"
      },
      checkin: {
        tech_issue: {
          title:
            "Siamo spiacenti, il servizio di check-in online non è disponibile",
          text: "Per favore contattateci se volete fare il check-in online"
        }
      },
      book_after_arrival_date: {
        title: "Offerta non più disponibile",
        text: "Siamo spiacenti, l'offerta scelta non è più disponibile. Vi preghiamo di contattarci per una nuova offerta."
      }
    },
    nav: {
      offer: "Offerta",
      highlights: "Highlights",
      info_services: "Info & Services",
      about: "About",
      contact: "Contact",
      terms_of_use: "Terms of use",
      privacy_policy: "Privacy Policy"
    },
    content: {
      today_reception: "Per te oggi alla reception:"
    },
    highlights: {},
    footer: {
      partners: "Partnerbetriebe",
      contact: "Contattateci",
      menu: "Navigazione",
      info: "Informazione"
    },
    account: {
      vat: "P.IVA",
      cin: "CIN (Codice Identificativo Nazionale)"
    },
    placeholder: {
      whatsapp: "Io avrei una domanda per quanto riguerda l'offerta #{offer}"
    }
  },
  entity: {
    best_offer: "La nostra migliore offerta per lei",
    details: "Dettagli",
    insurance: {
      text: "Prima di intraprendere un viaggio può sempre verificarsi un inconveniente. Con la nostra assicurazione storno viaggi siete assicurati dal punto di vista economico.",
      ctabtn: "ASSICURAZIONE DI VIAGGIO",
      title: "Assicurazione di viaggio"
    },
    selected: "Selezionato",
    select: "Selezionare",
    highlight: "Highlight",
    highlights_header: "Highlights",
    personal_info_header: "Info personale",
    personal_info: "Info personale",
    show_details: "Mostra dettagli",
    hide_details: "Nascondi dettagli",
    variant: "Opzione",
    variant_x: "Opzione {x}",
    nights_x: "1 notte|{x} notti",
    room_x: "Camera {x}",
    arrival: "Arrivo",
    arrival_daparture: "Arrivo/Partenza",
    persons_treatment: "Persone/Trattamento",
    room_category: "Categoria della camera",
    departure: "Partenza",
    offer: "Offerta",
    entity_of: " {n} di {count}",
    deposit_needed: "Vorauszahlung im Höhe von {amount} notwendig",
    no_deposit_needed: "Nessun deposito necessario",
    valid_till: "Offerta valida fino a:",
    valid_till_expired:
      "Questa offerta non è più valida, vi preghiamo di contattarci se desiderate ricevere un'altra offerta.",
    cancel_policy: {
      title: "Condizioni di cancellazione",
      show: "Mostra condizioni di cancellazione",
      free_cancellation: "Cancellazione gratuita",
      free_cancellation_until: "Cancellazione gratuita prima del "
    },
    mealplans: {
      HB: "Mezzo pensione",
      WS: "-",
      BB: "Bed&Breakfast",
      FB: "Vollpension",
      AI: "All Inclusive",
      RO: "Solo pernottamento"
    },
    room: {
      rooms: "Sistemazione | Camere",
      persons: "1 persona|{count} persone",
      child_pl: "1 Bambino|{count} Bambini",
      adult_pl: "1 Adulto|{count} Adulti",
      traveler: "Persone",
      treatment: "Trattamento",
      period: "Periodo",
      info: "Informazione",
      description: "Descrizione",
      room_change_on: "Cambio stanza"
    },
    treatment: {
      close: "Chiudi",
      booked_spa_header: "Gebuchte SPA Leistungen",
      date: "Data",
      time: "Ore",
      time_definition: "alle ore {time}",
      period: "Durata",
      show_details: "Mostra dettagli",
      time_slug: "min",
      package: {
        applied_spa: "Prestazioni",
        description: "Descrizione",
        total_amount: "Importo totale"
      },
      item: {
        description: "Descrizione",
        title: "Trattamento",
        quantity: "Quantità",
        price: "Prezzo",
        time: "Durata",
        guest_name: "Ospite",
        date: "Data",
        date_time: "Ora"
      }
    },
    price: {
      description: "Descrizione",
      description_short: "Descr.",
      quantity: "Numero",
      discount: "Sconto",
      amount: "Importo",
      taxes_included: "Kurtaxe inklusiv",
      show_price_details: "Mostra dettagli prezzi e condizioni di cancellazione",
      show_price_details_short: "Mostra dettagli prezzi",
      hide_price_details: "Nascondi dettagli prezzi",
      deposit_balance: "Caparra restante",
      price: "Prezzo",
      total_price: "Prezzo totale",
      deposit: "Caparra",
      deposit_amount: "Caparra da pagare",
      balance: "Saldo restante"
    },
    payment: {
      select_method: "Per favore scegliere una modalità di pagamento",
      payment_success:
        "Il pagamento di {amount} è stato effettuato con successo tramite {gateway_name} il {payment_created_data} alle ore {payment_created_time}.",
      authorized_bank_transfer_payment:
        "{gateway_name} è stato selezionato come metodo di pagamento per l'importo di {amount}.",
      success_bank_transfer_payment:
        "Il trasferimento di {amount} è stato effettuato con successo il {payment_changed_data} alle {payment_changed_time} tramite {gateway_name}.",
      default_payment_success:
        "Il pagamento di {amount} è stato effettuato con successo il {payment_created_data}.",
      default_payment_authorized:
        "Il pagamento di {amount} è stato registrato.",
      no_payment_methods:
        "Purtroppo questa offerta non può essere prenotata perché non sono stati definiti metodi di pagamento. Si prega di contattare l'albergo per ulteriori informazioni.",
      preauthorized_payment:
        "Il pagamento di {amount} è stato autorizzato con successo tramite {gateway_name} il {payment_created_data} alle {payment_created_time}.",
      methods: {
        credit_card: "Kreditkarte",
        credit_card_hint:
          "Kreditkarte: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        wire_transfere: "Überweisung",
        wire_transfere_hint:
          "Überweisung: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        paypal: "PayPal",
        paypal_hint:
          "PayPal: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur."
      },
      secure_checkout: "Secure Checkout",
      secure_checkout_hint:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat condimentum."
    },
    request: {
      thank_you: {
        header: "Grazie mille per la sua richiesta",
        text: "I vostri dati sono stati inoltrati con successo, sarà nostra premura elaborarli il prima possibile. Non esitate a contattarci per qualsiasi altra domanda.",
        button: "Chiudere"
      }
    },
    buttons: {
      book: "Prenotare",
      details: "Dettagli",
      coupon_apply: "Utilizza",
      reserve: "Prenota senza impegno"
    },
    expiration_time: "fino alle {time} CET"
  },
  personal_details: "Informazioni personali",
  checkin: {
    room_x_of: "Stanza",
    next: "Avanti",
    select: {
      country: "Seleziona paese",
      date: "Seleziona la data",
      time: "Seleziona l'ora"
    },
    allocate_room: "Assegnare la stanza",
    thank_you: {
      title: "Check-in online completato con successo!",
      text: "I vostri dati sono stati inoltrati con successo, sarà nostra premura elaborarli il prima possibile. Non esitate a contattarci per qualsiasi altra domanda."
    },
    title: "Online Check-In",
    guest_1: "Ospite principale",
    guest_2: "Ospite aggiuntivo",
    choose_room: "Seleziona la stanza"
  },
  promocode: {
    apply: "Utilizza",
    remove: "Rimuovere",
    label: "Codice promozionale",
    added: "Il codice di sconto è stato applicato",
    not_valid: "Il codice di sconto inserito non è valido",
    deleted: "Il codice di sconto è stato rimosso"
  },
  form: {
    errors: {
      selectPayMethod: "Per favore scegliere il metodo di pagamento"
    },
    back: "Indietro",
    close: "Chiudere",
    select_paymethod: "Sceglere il metodo di pagamento",
    checkout: "Checkout",
    salutation: "Titolo",
    title_options: {
      m: "Sig",
      f: "Sig.ra"
    },
    name: "Nome",
    firstname: "Nome",
    lastname: "Cognome",
    street: "Via",
    zip: "CAP",
    city: "Località",
    notes: "Ulteriori richieste",
    country: "Paese",
    email: "E-Mail",
    email_hint: "Lorem ipsum dolor sit amet consectetur.",
    phone: "Telefono",
    cellphone: "Numero di cellulare",
    phone_hint: "Lorem ipsum dolor sit amet consectetur.",
    contact: "Indirizzo",
    hint_booking: "{link_booking} Loren ipsum sit dolor",
    //your_request: 'La sua richiesta',
    easy_reservation: "Unverbindlich reservieren",
    terms:
      "Con la presente accetto le norme sulla privacy e le condizioni generali",
    checkin_terms: "Con la presente accetto le [norme sulla privacy].",
    newsletter:
      "Desidero ricevere regolarmente informazioni su offerte e novità.",
    check_terms: "Per favore accetta il privacy e le condizioni generali",
    error_occured:
      "Si è verificato un errore, controlla le tue informazioni e riprova",
    payment: {
      error: {
        title: "Si è verificato un errore",
        text: "Si prega di riprovare più tardi"
      }
    },
    doctypes: {
      ID_CARD: "Carta d'identità",
      DRIVERS_LICENSE: "Patente di guida",
      PASSPORT: "Passaporto"
    },
    labels: {
      additional_info: "Informazioni aggiuntive",
      document_info: "Documenti",
      birth_info: "Informazioni di nascita",
      address_info: "Indirizzo",
      contact_info: "Nome e contatto",
      assign_room: "Assegna stanza",
      other_info: "Altro"
    },
    region: "Provincia",
    upload: "Upload",
    country_iso: "Paese",
    mobilephone: "Cellulare",
    birth_date: "Data di nascita",
    birth_place: "Geburtsort",
    birth_municipality: "Comune",
    birth_country_iso: "Paese di nascita",
    birth_region: "Provincia",
    fiscal_code: "Codice fiscale",
    nationality_iso: "Nazionalità",
    document_type: "Tipo di documento",
    document_number: "Numero di documento",
    document_valid_from: "Emesso il",
    document_valid_to: "Valido al",
    document_issuing_country_iso: "Paese emittente",
    document_issuing_city: "Rilasciato da",
    arrival: "Arrivo",
    checkin_time: "Orario di arrivo",
    license_plate: "Targa del veicolo",
    driver_name: "Nome del conducente",
    car_brand: "Costruttore",
    terms_short: "Datenschutzerklärung akzeptieren",
    action_send: "Invia",
    gender_please_select: "per favore selezionare",
    municipality: "Comune"
  },
  dict: {
    for: "per"
  },
  booking: {
    default: {
      title: "La ringraziamo per la Sua richiesta!",
      text: "La Sua richiesta è stata inoltrata con successo, sarà nostra premura elaborarla il prima possibile. Non esiti a contattarci per qualsiasi altra domanda."
    },
    offer: {
      title: "La ringraziamo per la Sua richiesta di prenotazione!",
      text: "La Sua richiesta di prenotazione è stata inoltrata con successo, sarà nostra premura confermarla il prima possibile. Non esiti a contattarci per qualsiasi altra domanda."
    },
    order: {
      title: "La ringraziamo per la Sua prenotazione!",
      text: "La Sua prenotazione è stata inoltrata con successo, sarà nostra premura confermarla il prima possibile. Non esiti a contattarci per qualsiasi altra domanda."
    }
  },
  chat_window: {
    new_messages: "Sono presenti messaggi non letti!",
    message_empty: "Nessun messaggio",
    conversation_started: "La conversazione è iniziata il:",
    type_message: "Inserisci il messaggio",
    search: "Cerca",
    previewMode:
      "Non è possibile inviare messaggi di chat in modalità anteprima."
  },
  checkin_error_message:
    "Attenzione, questa campagna non è assegnata ad un ospite. È necessario aggiungere un ospite per poter compilare il modulo di check-in online.",
  checkout: {
    price_table: "Tabella prezzi"
  },
  chat_email_from: "da",
  upselling: {
    category: {
      category_title: "Categoria",
      without_category: "Servizi aggiuntivi senza categoria"
    },
    service: {
      service_title: "Servizi aggiuntivi",
      add_service: "Alla selezione",
      selected_service: "selezionato",
      change_service: "Modifica",
      add_to_cart_service: "Aggiungere",
      calc_modes: {
        MODE_PER_DAY: "per notte",
        MODE_PER_PERSON: "per persona",
        MODE_PER_DAY_AND_PERSON: "per notte e persona",
        MODE_PER_PRODUCT_UNIT: "per alloggio",
        MODE_PER_LIVING_UNIT_AND_DAY: "per alloggio & notte",
        MODE_PER_ITEM: "prezzo forfettario"
      },
      calc_info:
        "Il prezzo indicato è l'importo totale della modalità di calcolo" +
        ' "{calc_mode}" di questo servizio.',
      preselected_service_hotel: "Prenotato {n}x dall'hotel",
      preselected_service_customer: "Prenotato {n}x dall'ospite",
      show_more_services: "Mostrare tutti i servizi",
      hide_services: "Nascondere tutti i servizi"
    },
    service_price_table: {
      service: "Servizio",
      quantity: "Quantità",
      price: "Prezzo",
      amount: "Importo",
      description: "Descrizione"
    }
  },
  availability: {
    loading_message: "La disponibilità è in fase di verifica, potrebbe richiedere alcuni minuti.",
    request_offer_btn: "Invia nuova richiesta di preventivo",
    message: 'Questa offerta non è più disponibile. Desidero ricevere una nuova offerta!',
    request_error_message: "La richiesta non può essere inviata. Per favore riprova più tardi.",
    request_success_message: "La richiesta è stata inviata con successo.",
    fallback_unavailable_title: "Questa offerta non è più disponibile!",
    fallback_unavailable_text: "Ci dispiace informarla che l'offerta da lei richiesta non è attualmente più disponibile. Per inviare una nuova richiesta, la preghiamo di cliccare sul pulsante qui sotto. Il nostro team si occuperà immediatamente delle sue esigenze. È un piacere per noi aiutarla nella ricerca del soggiorno perfetto!"
  }
};
